@import "typography";
@import "elements";
@import "forms";
@import "navigation";
@import "widgets";
@import "media";
@import "content";

body > header {
  //background-image: url("../../assets/img/header.png");  
  //background-repeat: no-repeat;
  //background-size: 1213px 143px;
  height:143px;
  margin-left: 7.6923076923076923%;
  margin-right: 7.6923076923076923%;
}

.MJXc-TeX-type-R {
  box-sizing: inherit !important;
  font-family : Inconsolata, monospace !important;
  background-color: #d1d1d1 !important;
  padding: 0 0 !important;
  font-size: 1rem !important;
}


