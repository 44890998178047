body {
	margin: 0 auto;
	max-width: 1.1 * 75em;
	word-wrap: break-word;
}

%title {
	@extend .sans;
	font-weight: 700;
}

%heading {
	color: #1a1a1a;
	font-weight: 900;
	margin-bottom: 1.75rem;
}

.content * + %heading,
.content * + * %heading {
	margin-top: 3.5rem;
}

.content > footer h2 {
	margin-top: 0;
}

header %heading {
	@extend %title;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@extend %heading;
}


article > header time {
  text-transform: uppercase;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
}

h1 {
  text-transform: uppercase;
	font-size: 1.4375rem;
	line-height: 1.75rem;
}

h2 {
  text-transform: uppercase;
	font-size: 1.2375rem;
	line-height: 1.55rem;
}

h3 {
	font-size: 1.1875rem;
	line-height: 1.3125rem;
}

h4,
h5,
h6 {
	font-size: 1rem;
	line-height: 1.3125rem;
}

h4 {
	letter-spacing: 0.140625em; // 2.25px
	text-transform: uppercase;
}

h6 {
	font-style: italic;
}

header {
  color: #666666;
  font-family: Roboto;
	font-weight: 700;
	font-size: 1.0rem;
	line-height: 1.5rem;
}

body > header {
	padding: 2.625rem 7.6923076923076923%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

figure {
  margin-left: 0;
  margin-right: 0;
}

.brand {
	margin: 0.875em auto 0.875em 0;
}

.brand > h1 {
	margin: 0;
}

.brand > p {
	margin: 0.4375rem 0 0;
	display: none;
}

body > header > nav {
	display: none;
}

.linear,
.brand > h1 {
	font-size: 2.00rem;
	line-height: 2.20rem;
}

:checked ~ .menu {
	display: block;
}

:checked ~ .social {
	display: flex;
}

.toggle {
	background-color: transparent;
	border: 1px solid #d1d1d1;
	color: #1a1a1a;
	font-size: 0.8125rem;
	margin: 0.875rem 0;
	padding: 0.625rem;
}

.toggle:hover,
.toggle:focus {
	background-color: transparent;
	border-color: #007acc;
	color: #007acc;
}

:checked + .toggle {
	background-color: #1a1a1a;
	border-color: #1a1a1a;
	color: #fff;
	outline: thin dotted;
}

main {
	margin-bottom: 3.5em;
	padding-left: 7.6923076923076923%;
	padding-right: 7.6923076923076923%;
}

main > header {
	display: block;
	padding: 0;
	margin-bottom: 3.5rem;
	border-top: 4px solid #1a1a1a;
	padding-top: 1.75rem;
}

main > h1 {
	@extend %title;
	margin-bottom: 3.5rem;
}

main > :last-child {
	margin-bottom: 0;
}

article {
	margin-bottom: 3.5em;
	position: relative;
}

aside {
	margin-bottom: 3.5em;
	padding: 0 7.6923076923076923%;
}

footer {
	padding: 0 7.6923076923076923% 0.3125rem;
	color: #686868;
	font-size: 0.8125rem;
	line-height: 1.3125rem;
}

body > footer {
	clear: both;
}

article > footer {
	@extend .sans;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

article > footer > div {
	margin-bottom: 0.4375rem;
}

article > footer > * + div:not(:empty)::before {
	content: "/";
	opacity: 0.7;
	padding: 0 0.4375rem;
}

.content > footer {
	border-top: 1px solid #d1d1d1;
	padding-top: 1.75rem;
	padding-bottom: 0;
}

.content > footer .gravatar {
	float: left;
	margin-right: 1.75rem;
}

.content > footer > section {
	overflow: hidden;

	> :last-child {
		margin-bottom: 0;
	}
}

.content > footer h2 {
	font-size: 1rem;
	line-height: 1.75em;
	margin-bottom: 0;
}

@viewport {
	width: device-width;
	zoom: 1;
}

.quote {
  margin: auto; width: 80%; border: 2px solid #bbbbbb; padding: 10px;
}

.quote > * {
  color: red;
  display: inline; 
}
