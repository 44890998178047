.gravatar {
	border-radius: 50%;
}

article > footer > .gravatar {
	width: 1.3125rem;
	height: 1.3125rem;
	margin: -0.125rem 0.4375rem 0 0;
}

embed,
iframe,
object,
video {
	border: none;
	margin-bottom: 1.75em;
	max-width: 100%;
	vertical-align: middle;

	p > & {
		margin-bottom: 0;
	}
}
