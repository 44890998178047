@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');

.sans {
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
input,
select,
textarea {
	font-family: Merriweather, Georgia, "Times New Roman", Times, serif;
	color: #1a1a1a;
	line-height: 1.75;
}

html {
	font-synthesis: weight;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
	margin: 0;
}

p {
	margin: 0 0 1.75em;
}

blockquote {
	border: 0 solid #1a1a1a;
	border-left-width: 4px;
	color: #686868;
	font-size: 1.1875rem;
	font-style: italic;
	line-height: 1.5rem;
	margin: 0 0 1.5rem;
	padding: 0 0 0 1.75rem;
}

blockquote p {
	margin-bottom: 1.5rem;
}

blockquote cite,
blockquote small {
	color: #1a1a1a;
	display: block;
	font-size: 1rem;
	line-height: 1.75;

	&::before {
		content: "\2014\00a0";
	}
}

blockquote em,
blockquote i,
blockquote cite {
	font-style: normal;
}

blockquote > :last-child {
	margin-bottom: 0;
}

code,
kbd,
samp,
pre {
	font-family: Inconsolata, monospace;
}

pre {
	font-size: 1rem;
	line-height: 1.3125;
	margin: 0 0 0.75rem;
	max-width: 100%;
	overflow: auto;
	padding: 0.75rem;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

:not(td)>figure.highlight>pre  {
  border: 1px solid #d1d1d1;
}

td {
  vertical-align: top;
}

code {
	background-color: #d1d1d1;
	padding: 0.125em 0.25em;
}

pre > code {
	background-color: transparent;
	padding-left: 0;
	padding-right: 0;
}

abbr {
	border-bottom: 1px dotted #d1d1d1;
	cursor: help;
}

mark,
ins {
	background: #007acc;
	color: #fff;
	padding: 0.125em 0.25em;
	text-decoration: none;
}

time, .tagcloud > a {
  text-transform: uppercase;
  font-family: Roboto;
}
