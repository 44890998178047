aside > :last-child,
section > :last-child {
	margin-bottom: 0;
}

section {
	border-top: 2px solid #bbbbbb;
	margin-bottom: 2.0em;
  padding-top: 20px;
}

article {
	border-top: 2px solid #bbbbbb;
	padding-top: 20px;
}

section > h2 {
	@extend .sans;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 0.046875em;
	line-height: 1.3125;
	margin: 0 0 1.75em;
	text-transform: uppercase;
}

section > h2:empty {
	margin-bottom: 0;
}

.tagcloud > a {
	border: 1px solid #d1d1d1;
	border-radius: 2px;
	display: inline-block;
	@extend .sans;
	line-height: 1;
	margin: 0 0.1875em 0.4375em 0;
	padding: 0.5625em 0.4375em 0.5em;
}

.tagcloud > a:hover,
.tagcloud > a:focus {
	border-color: #007acc;
	color: #007acc;
	outline: 0;
}
